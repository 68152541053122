<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="教师信息">
              <a-input placeholder="请输入姓名/手机号" v-model="search.name" />
            </a-form-item>
          </a-col>

          <a-col :span="5">
            <a-form-item label="所属单位">
              <a-input placeholder="请输入" v-model="search.company" />
            </a-form-item>
          </a-col>
          <a-col :span="2" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          :roles="['教师-教师添加']"
          class="pxkeji_basic_btn"
          @click="showInfo(0)"
          type="primary"
        />
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="recommend" slot-scope="recommend">
          <a-tag v-if="recommend == 1" color="green">已推荐</a-tag>
          <a-tag v-else color="red">未推荐</a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            :roles="['教师-教师修改']"
            @click="showInfo(action.id)"
            size="small"
            type="primary"
          />
          <a-popconfirm
            title="是否确认删除该教师"
            @confirm="delInfo(action.id)"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              :roles="['教师-教师删除']"
              size="small"
              type="danger"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <TeacherInfo @callbackMethod="save" :id="addedit.id" />
    </a-modal>
  </div>
</template>
<script>
import TeacherInfo from "./teachertInfo.vue"; //新增编辑

const columns = [
  {
    dataIndex: "fullName",
    key: "fullName",
    align: "center",
    title: "姓名",
  },
  {
    dataIndex: "idCard",
    key: "idCard",
    align: "center",
    title: "身份证号",
  },
  {
    dataIndex: "telephone",
    key: "telephone",
    align: "center",
    title: "手机号",
  },
  {
    dataIndex: "company",
    key: "company",
    align: "center",
    title: "所属单位",
  },
  {
    dataIndex: "recommend",
    key: "recommend",
    align: "center",
    title: "推荐",
    scopedSlots: { customRender: "recommend" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "teacherList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      addedit: {
        title: "",
        id: 0,
        visible: false,
      },
      search: {
        //搜索条件
        name: "",
        company: "",
      },
      isloading: false,
    };
  },
  components: {
    TeacherInfo,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.teacher
        .getPagedList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.name,
          _this.search.company
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      //打开弹出框
      _this.addedit.title = id == 0 ? "添加" : "编辑";
      _this.addedit.id = id;
      _this.addedit.visible = true;
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.teacher
        .deleteById(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

