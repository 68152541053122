<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div class>
      <a-form-model
        ref="teacherForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="用户名" prop="userName">
          <span slot="extra" style="color: red">
            用户名只能是数字和字母组合。登录密码和用户名相同
          </span>
          <a-input placeholder="请输入" v-model="info.userName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="姓名" prop="fullName">
          <a-input
            placeholder="请输入"
            v-model="info.fullName"
            :maxLength="50"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="联系方式">
          <a-input
            placeholder="请输入"
            v-model="info.telephone"
            :maxLength="11"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="身份证号">
          <a-input
            placeholder="请输入"
            v-model="info.idCard"
            :maxLength="18"
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-radio-group v-model="info.sex">
            <a-radio value="1">男</a-radio>
            <a-radio value="2">女</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item label="简介">
          <vue-ueditor-wrap
            v-model="info.introduction"
            :config="myConfig"
          ></vue-ueditor-wrap>
        </a-form-model-item>
        <a-form-model-item label="擅长">
          <a-input placeholder="请输入" v-model="info.adept"></a-input>
        </a-form-model-item>
        <a-form-model-item label="所属单位">
          <a-input placeholder="请输入" v-model="info.company"></a-input>
        </a-form-model-item>
        <a-form-model-item label="职称">
          <a-input placeholder="请输入" v-model="info.title"></a-input>
        </a-form-model-item>
        <a-form-model-item label="照片">
          <a-upload
            accept="image/png,image/jpeg"
            listType="picture-card"
            :action="$api.file.uploadFile"
            :fileList="fileList"
            :beforeUpload="beforeUpload"
            @preview="handlePreview"
            @change="handleChange"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="info.photoUrl" />
          </a-modal>
        </a-form-model-item>
        <a-form-model-item label="是否推荐">
          <a-switch
            v-model="info.recommend"
            checked-children="是"
            un-checked-children="否"
          />
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 10, offset: 4 }">
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-spin>
</template>

<script>
import VueUeditorWrap from "vue-ueditor-wrap"; // ES6 Module
let _this;
export default {
  name: "TeacherInfo",
  props: {
    id: null,
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 17 },
      rules: {
        fullName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            validator: (rule, val, callback) => {
              var pattern = /^\w+$/;
              if (!pattern.test(val)) {
                callback("用户名格式错误");
              } else {
                callback();
              }
              callback();
            },
          },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
      info: {
        fullName: "",
        userName: "",
        telephone: "",
        idCard: "",
        sex: "",
      },
      isloading: false,
      loadingTip: "加载中...",
      fileList: [],
      previewVisible: false,
      myConfig: {
        autoHeightEnabled: false,
        initialFrameHeight: 180,
        wordCount: true,
        maximumWords: 100000,
        initialFrameWidth: "100%",
        serverUrl: this.$api.file.uploadUpFile,
        UEDITOR_HOME_URL: "/UEditor/",
        toolbars: this.$commen.editorConfig.toolbars,
      },
    };
  },
  components: {
    VueUeditorWrap,
  },
  mounted() {
    _this = this;
    if (_this.id) {
      _this.getInfo();
    }
  },
  methods: {
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    /**数据回显 */
    getInfo() {
      _this.isloading = true;
      _this.$api.teacher
        .getById(_this.id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.info.recommend = _this.$commen.changeIntBoolean(
              res.data.recommend
            );
            res.data.recommend = _this.$commen.changeIntBoolean(
              res.data.recommend
            );
            if (res.data.photoUrl) {
              _this.fileList.push({
                uid: 1,
                name: "头像.png",
                status: "done",
                url: res.data.photoUrl,
              });
            }
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },

    handleSubmit() {
      _this.$refs.teacherForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let paramData = {
            fullName: _this.info.fullName,
            sex: _this.info.sex,
            userName: _this.info.userName,
            idCard: _this.info.idCard ? _this.info.idCard : "",
            telephone: _this.info.telephone ? _this.info.telephone : "",
            introduction: _this.info.introduction
              ? _this.info.introduction
              : "",
            company: _this.info.company ? _this.info.company : "",
            title: _this.info.title ? _this.info.title : "",
            photoUrl: _this.info.photoUrl,
            recommend: _this.$commen.changeBooleanInt(_this.info.recommend),
            adept: _this.info.adept,
          };
          if (_this.id) {
            paramData.id = _this.id;
          }
          _this.$api.teacher
            .saveOrUpdate(paramData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.$emit("callbackMethod");
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },

    handleCancel() {
      _this.previewVisible = false;
    },
    beforeUpload(file) {
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(info) {
      _this.fileList = info.fileList;
      if (info.file.status == "done") {
        if (info.file.response.errorCode == "0000") {
          _this.info.photoUrl = info.file.response.data.path;
        } else {
          _this.$message.error("上传失败");
        }
      } else if (info.file.status == "error") {
        _this.$message.error("上传失败");
        _this.fileList = [];
      } else if (info.file.status == "removed") {
        _this.info.photoUrl = "";
      }
    },
  },
};
</script>
<style>
.scheduleBtn {
  color: #1890ff;
  cursor: pointer;
}
</style>